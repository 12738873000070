<template>
  <div>
    <!--====== FAQ START ======-->
    <k-FAQ-detail />
    <!--====== FAQ ENDS ======-->
  </div>
</template>

<script>
import KFAQDetail from '@/components/faq/KFAQDetail.vue';

export default {
  components: {
    KFAQDetail
  }
}
</script>

<style>

</style>