<template>
  <section class="contact-section" style="padding-top: 30px !important;">
    <div class="topGapRegister">
    </div>
    <div class="container">
      <div class="">
        <img src="@/assets/images/separator2.png" width="200" style="margin-top: 40px;">
      </div>
      <span style="color: white; font-size: 24px; margin-bottom: 30px; margin-top: 25px; filter: brightness(100%) !important;"><b>Register Now</b></span>
      <div class="row">
        <div class="col-md-8">
          <div class="contact-form">
            <form @submit.prevent="submit" class="row">
              <div class="col-md-6">
                <input type="text" v-model="name" name="name" placeholder="Your Full Name" required>
              </div>
              <div class="col-md-6">
                <input type="number" class="numberBtn" v-model="phone" name="phone" placeholder="Your Contact Number" required>
              </div>
              <div class="col-md-6">
                <input type="email" v-model="email" name="email" placeholder="Email" required>
              </div>
              <div class="col-md-6">
                <input type="text" v-model="brand" name="brand" placeholder="Name of Brand" required>
              </div>
              <div class="col-md-12">
                <input type="text" v-model="role" name="role" placeholder="Your Role in This Brand" required>
              </div>
              <!-- <div class="col-md-12">
                <textarea name="message" v-model="message" placeholder="Your message" required></textarea>
              </div> -->
              <div class="col-md-6 text-right">
                <input type="submit" :disabled="submitDisable" name="submit" message="message">
              </div>
            </form>
            <div v-if="loading" class="loading-screen">
              <div class="spinner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
require('dotenv').config()
export default {
  data: () => ({
    name: '',
    phone: '',
    email: '',
    brand: '',
    role: '',
    message: '',
    loading: false,
    submitDisable: false,
  }),
  
  methods: {
    async submit() {
      console.log(this.name, 'name')
      console.log(process.env.VUE_APP_PRIVATE_KEY, 'envemail')
      const { GoogleSpreadsheet } = require('google-spreadsheet')
      /* old spreadsheet ID */
      const doc = new GoogleSpreadsheet('19izR5GLSCyn5xoTqv99TioAYxnGoNNpGHQvGuMW0xbw')
      /* new spreadsheet ID */
      // const doc = new GoogleSpreadsheet('1L1oBVXDqjIUNk2nOQ9VqWbkGTBl5UP5styTcC3tTa8g')
      const privatekey = process.env.VUE_APP_PRIVATE_KEY.replace(/\\n/g, '\n')
      this.loading = true
      this.submitDisable = true
      await doc.useServiceAccountAuth({
        client_email: process.env.VUE_APP_EMAIL,
        private_key: privatekey
      })
      
      await doc.loadInfo()

      /* Old spreadsheet location: https://docs.google.com/spreadsheets/d/19izR5GLSCyn5xoTqv99TioAYxnGoNNpGHQvGuMW0xbw/edit#gid=1726975773 */
      /* New spreadsheet location: https://docs.google.com/spreadsheets/d/1L1oBVXDqjIUNk2nOQ9VqWbkGTBl5UP5styTcC3tTa8g/edit#gid=1726975773 */

      const sheet = await doc.sheetsByTitle['Batch II']
      const larryRow = await sheet.addRow({ name: this.name, phone: this.phone, email: this.email, brand: this.brand, role: this.role })
      console.log(larryRow, '<<<<<')
      window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScQ-1_9Af1111jLpD0q6vjnMyWr6_v68HzkPofrOB9s2_keEg/viewform?usp=sf_link'
    },
    check () {
      this.error = []
      if (!this.name){
        this.error.push('Name required')
      }
    }
  }
}
</script>

<style>
.topGapRegister {
  height: 100px !important;
  background-color: transparent !important;
}
.numberBtn::-webkit-inner-spin-button,
.numberBtn::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}
::placeholder {
    font-family: Montserrat;
}
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffffff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>