<template>
  <!--====== FOOTER DETAILS START ======-->
  <section style="background-color: #1f2a5a;" class="appie-footer-area appie-footer-3-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="footer-widget-info">
            <div class="logo align-items-center justify-content-center" style="display: flex;">
              <img class="footerView" src="@/assets/images/kinovationwhite.png" width="250" alt="">
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="footer-widget-info align-items-center justify-content-center" style="display: flex; margin-bottom: 50px;">
            <a style="margin-right: 10px" target="_blank" href="https://www.instagram.com/kinovation.id/"><img src="@/assets/images/Footer_Instagram.png" width="30"></a>
            <a style="margin-right: 10px" target="_blank" href="https://www.linkedin.com/company/kinovation/"><img src="@/assets/images/Footer_in.png" width="30"></a>
            <a style="margin-right: 30px" target="_blank" href="https://www.facebook.com/profile.php?id=100094395901783"><img src="@/assets/images/Footer_Facebook.png" width="30"></a>
            <a href="mailto:hello@kinnovation.id"><img src="@/assets/images/Footer_Email.png" width="30"></a> <span style="color:white; margin-left: 10px;">hello@kinovation.id </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div style="text-align: center;" class="">
            <img src="@/assets/images/separator2.png" width="180">
          </div>
          <div class="d-flex align-items-center justify-content-center copyView">
            <div class="copyright-text">
              <div class="copyView">Copyright © 2023 Kinovation. All rights reserved.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== FOOTER DETAILS ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>
.copyView {
  font-size: 14px !important;
  text-align: center !important;
  color: #fff !important;
}

@media screen and (orientation:portrait) {
  .footerView {
    width: 70vw;
  }  
}
</style>