<template>
  <div>
    <!--====== HERO START ======-->
    <k-home-slideshow-hero />
    <k-home-subtitle-hero />
    <!--====== HERO ENDS ======-->

    <!--====== BRANDS START ======-->
    <k-brand-current-batch-and-alumni />
    <!-- <k-brand-just-alumni /> -->
    <!--====== BRANDS ENDS ======-->

    <!--====== FEATURES START ======-->
    <k-home-features />
    <!--====== FEATURES ENDS ======-->

    <!--====== NEWS START ======-->
    <k-home-news />
    <!--====== NEWS END ======-->
  </div>
</template>

<script>
import KHomeSlideshowHero from '@/components/home/KHomeSlideshowHero.vue'
import KHomeSubtitleHero from '@/components/home/KHomeSubtitleHero.vue';
import KBrandCurrentBatchAndAlumni from '@/components/brands/KBrandCurrentBatchAndAlumni.vue';
// import KBrandJustAlumni from '@/components/brands/KBrandJustAlumni.vue';
import KHomeFeatures from '@/components/home/KHomeFeatures.vue';
import KHomeNews from '@/components/home/KHomeNews.vue';

export default {
  components: {
    KHomeSlideshowHero,
    KHomeSubtitleHero,
    KBrandCurrentBatchAndAlumni,
    // KBrandJustAlumni,
    KHomeFeatures,
    KHomeNews,
  }

};
</script>

<style>
.v-application .code1--text {
  color: #005e9f !important;
}
.v-application .code2--text {
  color: #1f2a5a !important;
}
.v-application .headline {
  font-size: 1.2rem !important;
}

@media screen and (orientation:landscape) {
  .platView {
    margin-left: 0px !important;
  }
  .webView {
    margin-left: 0px !important;
  }
  .fontSize {
    color: black;
    width: 100%;
    margin-top: 50px !important;
    font-size: 20px !important;
    text-align: center;
    margin-bottom: 150px !important;
  }
}
@media screen and (orientation:portrait) {
  .platView {
    width: 150px !important;
  }
  .fontSize {
    color: black;
    width: 100%;
    margin-top: 50px !important;
    font-size: 16px !important;
    text-align: center;
    margin-bottom: 40px !important;
  }
}
</style>