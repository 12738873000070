<template>
  <!--====== SIDEBAR MENU DETAILS START ======-->
  <div>
    <div
      class="off_canvars_overlay"
      :class="[showSidebar ? 'active' : '']"
      @click.prevent="hideSidebar"
    ></div>
    <div class="offcanvas_menu">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div
              class="offcanvas_menu_wrapper"
              :class="[showSidebar ? 'active' : '']"
              style="overflow-y: scroll; overflow-x: hidden"
            >
              <div class="canvas_close">
                <a href="javascript:void(0)" @click="hideSidebar"
                  ><i class="fa fa-times"></i
                ></a>
              </div>
              <div class="offcanvas-brand text-center mb-40">
                <img class="navView" src="@/assets/images/kinovation.png" alt="appie" />
              </div>
              <div id="menu" :style="heightPro" class="text-left">
                <ul class="offcanvas_main_menu">
                  <li
                    v-for="(item, index) in menuItems"
                    :key="index"
                    class="menu-item-has-children active"
                    :class="[menuOpen === item.name ? 'menu-open' : '']"
                  >
                    
                    <!-- Modified: Allow external link -->
                    <a v-if="item.is_external" :href="item.path" target="_blank" style="text-transform: capitalize;" >{{ item.name }}</a>
                    <router-link
                      v-else
                      style="text-transform: capitalize"
                      :to="item.path">
                      {{ item.name }}
                    </router-link>

                    <!-- Original: Allows submenu -->
                    <!-- <span
                      v-if="
                        item.childrens && item.childrens.length > 0 && nested
                      "
                      class="menu-expand"
                      ><i class="fa fa-angle-down"></i
                    ></span>
                    <router-link
                      style="text-transform: capitalize"
                      v-if="item.path"
                      :to="item.path"
                    >
                      {{ item.name }}</router-link
                    >
                    <a v-else href="#" @click.prevent="show(item.name)">
                      {{ item.name }}</a
                    >
                    <ul
                      v-if="item.childrens && nested"
                      :id="item.name"
                      class="sidebar-sub-menu"
                      :class="[
                        menuOpen === item.name ? 'expend_menu_item' : '',
                      ]"
                    >
                      <li v-for="(subItem, i) in item.childrens" :key="i">
                        <a v-if="subItem.is_external" :href="subItem.path" target="_blank">{{ subItem.name }}</a>
                        <router-link v-else :to="subItem.path ? subItem.path : '#'">
                          {{ subItem.name }}
                        </router-link>
                      </li>
                    </ul> -->
                  </li>
                </ul>
              </div>
              <div class="footer-widget-info" style="text-align: left; position: absolute; bottom: 50px; left:20px;">
                <ul>
                 <li><a href="mailto:hello@kinovation.id"><i class="fal fa-envelope"></i> hello@kinovation.id</a></li>
                 <li><a href="https://www.instagram.com/kinovation.id/"><i class="fab fa-instagram"></i> @kinovation.id</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====== SIDEBAR MENU DETAILS ENDS ======-->
</template>

<script>
import { store } from '@/store';

export default {
  props: {
    showSidebar: {
      type: Boolean,
      required: true,
    },
    nested: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      menuOpen: null,
      subMenuHeight: null,
      menuItems: store.state.navs
    };
  },

  computed: {
    heightPro() {
      return {
        "--height": this.subMenuHeight,
      };
    },
  },

  methods: {
    hideSidebar(e) {
      this.$emit("toggleSidebar", e);
    },
    show(value) {
      if (value !== this.menuOpen) {
        this.menuOpen = value;
      } else {
        this.menuOpen = null;
      }
      const getListItem = document.querySelectorAll(`#${value} li`).length;
      this.subMenuHeight = 43 * getListItem + "px";
    },
  },

};

</script>

<style>
.sidebar-sub-menu {
  padding-left: 20px;
  overflow: hidden;
  height: 0px;
}
.sidebar-sub-menu {
  transition: all linear 0.65s;
}
.sidebar-sub-menu.expend_menu_item {
  transition: all linear 0.65s;
}
.expend_menu_item.sidebar-sub-menu {
  height: var(--height);
}
@media screen and (orientation:portrait) {
    .navView {
        width: 55vw !important;
    }
}
</style>