<template>
  <!--====== HERO SUBTITLE START ======-->
  <section class="pb-50 pt-50">
    <div class="container">
      <div class="row justify-content-center">
        <div class="">
          <div class="appie-hero-content text-center">
            <!-- <h1 class="appie-title">{{title}}</h1> -->
            <img src="@/assets/images/kinovation.png" alt="" class="view mb-10">
            <!-- <button @click="showEffect">test</button> -->
            <Transition
              @before-enter="beforeEnter"
              @enter="onEnter"
            >
              <div v-if="show">
                <p class="fontView">
                  Empowering Indonesian Brands of Tomorrow
                </p>
                <!-- <p class="fn_animated_text">tulisan biasa</p> -->
              </div>
            </Transition>
            <span style="color: black; font-size: 18px;" class="">
              D2C accelerator program powered by Kino Indonesia that aims to support Indonesian brands <br>with 1 month long bootcamp led by industry experts, 1-on-1 mentoring sessions, office visit & demo day
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== HERO SUBTITLE ENDS ======-->
</template>

<script>
import gsap from 'gsap'
export default {
  components: {

  },

  props: {
    // title:{
    //   type:String
    // },
    // description:{
    //   type:String
    // },
    // more_description:{
    //   type:String
    // },
    // videoUrl:{
    //   type:String
    // }
  },

  data() {
    return{
      showVideo:false,
      show: true
    }
  },

  methods: {
    openVideo(){
      this.showVideo = !this.showVideo
    },

    beforeEnter (el) {
      gsap.set(el, {
        scaleX: 0.25,
        scaleY: 0.25,
        opacity: 1
      })
    },

    onEnter(el, done) {
      gsap.to(el, {
        duration: 2,
        scaleX: 1,
        scaleY: 1,
        opacity: 1,
        ease: 'elastic.inOut(2.5, 1)',
        onComplete: done
      })
    },
    showEffect(){
      this.show = !this.show
      console.log(this.show, '<<<<')
    }
  },

  mounted() {
    // setTimeout(() => {
    //   this.show = true
    // }, 300)
  }

}
</script>

<style>
@media screen and (orientation:portrait) {
  .view {
      width: 70vw;
      margin-bottom: 30px !important;
  }
  .fontView {
      font-size: 16px !important;
      color: #005e9f !important;
  }
  .fontSize {
      font-size: 9px !important;
  }
}
@media screen and (orientation:landscape) {
  .fontView {
      font-size: 24px !important;
      color: #005e9f !important;
  }
  .view {
      width: 300px !important;
  }
  .fontSize {
      font-size: 16px !important;
  }
}
</style>