<template>
  <!--====== FEATURES DETAILS START ======-->
  <section class="pt-50 pb-50" style="background-color: #edf1f4;" id="features">
    <div class="container">
      <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="appie-section-title text-center">
              <span class="titleFontTop">Participants will be fueled by interactive classes and sessions</span>
            </div>
            <!-- <div style="display: flex; justify-content: center;">
              <div class="line justify-content-center"></div>
            </div> -->
          </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div style="background-color: white !important" class="appie-single-service appie-single-services-3 text-center mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
            <div class="" >
              <img src="@/assets/images/bootcamp.png" width="30" alt="">
            </div>
            <h4 class="appie-title titleFontSession">Bootcamp</h4>
            <p>Comprehensive curriculum delivered by experts and leaders in the D2C industry</p>
            <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="appie-single-service appie-single-services-3 text-center mt-30 item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
            <div class="">
              <img src="@/assets/images/mentoring.png" width="30" alt="">
            </div>
            <h4 class="appie-title titleFontSession">1:1 Mentoring</h4>
            <p>One-to-one sessions for more focused specific topics</p>
            <br>
            <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="appie-single-service appie-single-services-3 text-center mt-30 item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
            <div class="">
              <img src="@/assets/images/visit.png" width="30" alt="">
            </div>
            <h4 class="appie-title titleFontSession">Office Visit</h4>
            <p>Opportunity to visit Kino's headquarters & learn more about the company</p>
            <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="appie-single-service appie-single-services-3 text-center mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
            <div class="">
              <img src="@/assets/images/demo_day.png" width="30" alt="">
            </div>
            <h4 class="appie-title titleFontSession">Demo Day</h4>
            <p>Opportunity to showcase products in front of investors & business partners</p>
            <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== FEATURES DETAILS ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>
@media screen and (orientation:portrait) {
.titleFontSession {
    font-size: 26px !important;
    color: #005e9f;
}
.line {
    width: 70px;
    height: 8px;
    background-color: #F7A620;
    margin-top: -10px;
}
}
@media screen and (orientation:landscape) {
.titleFontSession {
    font-size: 24px !important;
    color: #005e9f;
}
.titleFontTop {
    font-size: 24px !important;
    color: #005e9f;
}
.line {
    width: 70px;
    height: 8px;
    background-color: #F7A620;
    margin-top: -10px;
}
}
</style>