import Vue from 'vue'
import VueRouter from 'vue-router'

import KHome from '@/views/KHome.vue'
import KBrands from '@/views/KBrands.vue'
import KTimeline from '@/views/KTimeline.vue'
// import News from '@/views/News.vue'
import KEvents from '@/views/KEvents.vue'
import KEventDetail from '@/views/KEventDetail.vue'
import KFAQ from '@/views/KFAQ.vue'
import KRegister from '@/views/KRegister.vue'
import KError from '@/views/KError.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'KHome',
    component: KHome
  },
  {
    path: '/brands',
    name: 'brands',
    component: KBrands
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: KTimeline
  },
  // {
  //   path: '/news',
  //   name: 'News',
  //   component: News
  // },
  {
    path: '/events',
    name: 'events',
    component: KEvents
  },
  {
    path: '/event/:id',
    name: 'eventDetail',
    component: KEventDetail
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: KFAQ
  },
  {
    path: '/register',
    name: 'register',
    component: KRegister
  },
  {
    path: '/error',
    name: 'error',
    component: KError
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // stop stop cache
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

export default router
