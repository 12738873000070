<template>
  <div>
    <!--====== TIMELINE START ======-->
    <k-timeline-details />
    <!--====== TIMELINE ENDS ======-->
  </div>
</template>

<script>
import KTimelineDetails from '@/components/timeline/KTimelineDetails.vue';

export default {
  components: {
    KTimelineDetails,
  },
}
</script>

<style>

</style>