<template>
  <v-app>
    <v-main>
      <!--====== SIDEBAR MENU START ======-->
      <k-sidebar-menu
        :showSidebar="showSidebar"
        @toggleSidebar="toggleSidebar"
      />
      <!--====== SIDEBAR MENU ENDS ======-->

      <!--====== HEADER MENU START ======-->
      <k-header-menu 
        @toggleSidebar="toggleSidebar"
      />
      <!--====== HEADER MENU ENDS ======-->
      
      <!--====== PAGE CONTENT START ======-->
      <router-view/>
      <!--====== PAGE CONTENT ENDS ======-->

      <!--====== FOOTER START ======-->
      <k-footer />
      <!--====== FOOTER ENDS ======-->

      <!--====== BACK TO TOP START ======-->
      <div class="back-to-top back-to-top-3">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
      </div>
      <!--====== BACK TO TOP ENDS ======-->
    </v-main>
  </v-app>
</template>

<script>
import KSidebarMenu from '@/components/common/KSidebarMenu.vue';
import KHeaderMenu from '@/components/common/KHeaderMenu.vue';
import KFooter from '@/components/common/KFooter.vue';

export default {
  name: 'KinoWebApp',

  components: {
    KSidebarMenu,
    KHeaderMenu,
    KFooter
  },

  data: () => ({
    showSidebar: false,
  }),

  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },

  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },

    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    }
  }
};
</script>

<style>
@import '../src/assets/css/plugins.css';

.back-to-top.back-to-top-3 a {
  background: #fff !important;
}
</style>
