import Vue from 'vue'
import Vuex from 'vuex'

/* News related images */
// {news}{section}{number}
import news11 from "@/assets/images/news/news-1-1.jpg";
import news12 from "@/assets/images/news/news-1-2.jpg";
import news13 from "@/assets/images/news/news-1-3.jpg";
// News Section 2
// {news}{section}{number}
import news21 from "@/assets/images/news/news-2-1.jpg";
import news22 from "@/assets/images/news/news-2-2.jpg";
import news23 from "@/assets/images/news/news-2-3.jpg";
// News Section 3
// {news}{section}{number}
import news31 from "@/assets/images/news/news-3-1.jpg";
import news32 from "@/assets/images/news/news-3-2.jpg";
import news33 from "@/assets/images/news/news-3-3.jpg";
// News Section 3
// {news}{section}{number}
import news41 from "@/assets/images/news/news-4-1.jpg";
import news42 from "@/assets/images/news/news-4-2.jpg";
import news43 from "@/assets/images/news/news-4-3.jpg";

import news03062024 from '@/assets/images/news/news-03-06-2024.jpg'

/* Event related images */
import foundersLogo from "@/assets/images/events/kinofm.png";
// import logo1 from "@/assets/images/events/fm1.jpeg";
import eventlogo3 from "@/assets/images/events/fm3.jpg";
import fmPoster1 from "@/assets/images/events/fmP1.jpeg";
import fmPoster2 from "@/assets/images/events/fmp2.jpeg";
// import fmPoster3 from "@/assets/images/events/fmp3.jpg";

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    rtl: true, // legacy states from appie
    enableRtl:false, // legacy states from appie
    currentBatchName: 'Batch III',
    navs: [
      {
        name: "home",
        path: "/"
      },
      {
        name: "brands",
        path: "/brands"
      },
      {
        name: "timeline",
        path: "/timeline"
      },
      {
        name: "events",
        path: "/events"
      },
      {
        name: "FAQ",
        path: "/faq",
      },
      {
        name: "Register Now",
        // path: "/register",
        path: "https://docs.google.com/forms/d/e/1FAIpQLScQ-1_9Af1111jLpD0q6vjnMyWr6_v68HzkPofrOB9s2_keEg/viewform?usp=sf_link",
        is_external: true,
        class: "join-us"
      },
    ],
    news_list: [
      {
        img: news03062024,
        date: "11 Jun 2024",
        category: "SWA",
        href: "https://swa.co.id/read/447628/kino-luncurkan-program-brand-akselerator-batch-ketiga",
        title: "Kino Luncurkan Program Brand Akselerator Batch Ketiga"
      },
      {
        img: news03062024,
        date: "03 Jun 2024",
        category: "Warta Ekonomi",
        href: "https://wartaekonomi.co.id/read536398/kino-hadirkan-kinovation-jalan-startup-untuk-perkuat-jaringan-bisnis",
        title: "Kino Hadirkan Kinovation, Jalan Startup untuk Perkuat Jaringan Bisnis"
      },
      {
        img: news03062024,
        date: "03 Jun 2024",
        category: "Kontan",
        href: "https://pressrelease.kontan.co.id/news/komitmen-kino-indonesia-dukung-perkembangan-brand-lokal",
        title: "Komitmen Kino Indonesia Dukung Perkembangan Brand Lokal #KinovasiUntukIndonesia"
      },
      {
        img: news13,
        date: "15 Sep 2023",
        category: "Investor.id",
        href: "https://investor.id/lifestyle/340670/kino-indonesia-kino-pilih-10-brand-lokal-ikuti-program-akselerator",
        title: "Kino Indonesia (KINO) Pilih 10 Brand Lokal Ikuti Program Akselerator",
      },
      {
        img: news11,
        date: "11 Sep 2023",
        category: "Bisnis.com",
        href: "https://entrepreneur.bisnis.com/read/20230911/52/1693838/10-brand-lokal-yang-dinilai-punya-inovasi",
        title: "10 Brand Lokal yang Dinilai Punya Inovasi",
      },
      {
        img: news12,
        date: "11 Sep 2023",
        category: "Tribun News",
        href: "https://wartakota.tribunnews.com/2023/09/11/belajar-dari-ahlinya-10-brand-lokal-terpilih-ikut-program-akselerator-kinovation-batch-2",
        title: "Belajar dari Ahlinya, 10 Brand Lokal Terpilih Ikut Program Akselerator Kinovation Batch 2",
      },
      {
        img: news21,
        date: "10 Apr 2023",
        category: "Suara",
        href: "https://www.suara.com/bisnis/2023/04/10/081527/10-startup-diberi-kesempatan-mempresentasikan-produknya-di-hadapan-pemain-industri-dan-investor-di-demo-day",
        title: "10 Startup Diberi Kesempatan Mempresentasikan Produknya di Hadapan Pemain Industri dan Investor di Demo Day",
      },
      {
        img: news22,
        date: "9 Apr 2023",
        category: "Berita Satu",
        href: "https://www.beritasatu.com/ekonomi/1037309/tutup-program-akselerator-d2c-gelombang-pertama-kinovation-gelar-demo-day",
        title: "Tutup Program Akselerator D2C Gelombang Pertama, Kinovation Gelar Demo Day",
      },
      {
        img: news23,
        date: "7 Apr 2023",
        category: "Warta Ekonomi",
        href: "https://wartaekonomi.co.id/read491907/tutup-program-akselerator-d2c-batch-pertama-kinovation-gelar-demo-day",
        title: "Tutup Program Akselerator D2C Batch Pertama, Kinovation Gelar Demo Day"
      },
      {
        img: news31,
        date: "14 Mar 2023",
        category: "Tribun",
        href: "https://www.tribunnews.com/bisnis/2023/03/14/pertumbuhan-dan-perkembangan-sektor-umkm-bisa-ditingkatkan-lewat-program-akselerator",
        title: "Pertumbuhan dan Perkembangan Sektor UMKM Bisa Ditingkatkan Lewat Program Akselerator"
      },
      {
        img: news32,
        date: "13 Mar 2023",
        category: "Investor.id",
        href: "https://investor.id/business/324378/kino-indonesia-pilih-10-brand-lokal-untuk-kinovation",
        title: "Kino Indonesia Pilih 10 Brand Lokal untuk Kinovation",
      },
      {
        img: news33,
        date: "13 Mar 2023",
        category: "Media Indonesia",
        href: "https://mediaindonesia.com/ekonomi/565077/10-brand-lokal-umkm-terpilih-ikuti-program-kinovation",
        title: "Kino Indonesia Pilih 10 Brand Lokal untuk Kinovation",
      },
      {
        img: news42,
        date: "28 Feb 2023",
        category: "TechinAsia",
        href: "https://id.techinasia.com/kino-indonesia-program-akselerator-d2c",
        title: "Kino Indonesia Luncurkan Program Akselerator untuk Brand D2C",
      },
      {
        img: news41,
        date: "27 Feb 2023",
        category: "Kompas",
        href: "https://money.kompas.com/read/2023/02/27/090000826/peluang-umkm-berbisnis-d2c-brand-terbuka-lebar-kino-buka-program-pelatihan?page=all",
        title: "Peluang UMKM Berbisnis 'D2C Brand' Terbuka Lebar, Kino Buka Program Pelatihan"
      },
      {
        img: news43,
        date: "25 Feb 2023",
        category: "Detik Finance",
        href: "https://finance.detik.com/berita-ekonomi-bisnis/d-6588515/produk-lokal-dan-umkm-bisa-tambah-maju-begini-caranya",
        title: "Produk Lokal dan UMKM Bisa Tambah Maju, Begini Caranya",
      }
    ],
    timeline: [
      {
        dateColor: 'code1',
        titleColor: 'code2',
        date: 'Fri, 10 May',
        title: 'Batch I & II Alumni Gathering'
      },
      {
        dateColor: 'code1',
        titleColor: 'code2',
        date: 'Thu, 30 May',
        title: 'Kinovation Founders Meetup: Elevate Your D2C Brand in a Competitive Market'
      },
      {
        dateColor: 'code1',
        titleColor: 'code2',
        date: '30 May - 30 Jun',
        title: 'Open Registration Batch III'
      },
      {
        dateColor: 'code1',
        titleColor: 'code2',
        date: '12, 19, 26 Jun*',
        title: 'Founders Circle (*selected dates)'
      },
      {
        dateColor: 'code1',
        titleColor: 'code2',
        date: 'Thu, 11 Jul',
        title: 'Final Interview'
      },
      {
        dateColor: 'code1',
        titleColor: 'code2',
        date: '22 - 26 Jul',
        title: 'Bootcamp'
      },
      {
        dateColor: 'code1',
        titleColor: 'code2',
        date: 'Thu, 25 Jul',
        title: 'Welcoming Dinner'
      },
      {
        dateColor: 'code1',
        titleColor: 'code2',
        date: '26 Jul - 14 Aug',
        title: '1:1 Mentoring & Pitch Practice'
      },
      {
        dateColor: 'code1',
        titleColor: 'code2',
        date: 'Thu, 22 Aug',
        title: 'Demo Day'
      },
    ],
    events: [
      {
        id: 1,
        date: 'May 30, 2024',
        title: 'Elevate Your D2C Brand in a Competitive Market',
        image: foundersLogo,
        canShowDetails: false,
        details: {
          time: '16:00 - 17:00',
          location: 'Senyawa+ Space, 2nd floor',
          location_detail: 'Jalan Raden Saleh Raya No. 46A, Cikini, Menteng, Jakarta Pusat',
          panelists: ['Devi Nugraha, Senior Lead Corporate Communications, GoTo Group', 'Rama Suparta, Co-Founder Saturdays', 'Ranggaz Laksamana, Content Creator'],
          register:"https://bit.ly/KinovationFM",
          image: fmPoster1,
        }
      },
      {
        id: 2,
        title: 'Preparing Your D2C Brand to Fundraise',
        date: 'Wednesday, 9 August 2023',
        image: eventlogo3,
        canShowDetails: false,
        details: {
          time: 'Coming Soon',
          location: 'Senyawa+ Space, 2nd floor',
          location_detail: 'Jalan Raden Saleh Raya No. 46A, Cikini, Menteng, Jakarta Pusat',
          panelists: ['Harry Tumengkol, Founder Image Dynamics', 'Joshua Prameswara, Investment Professional SIG Venture Capital', 'Yuliana Nyoto, Founder & CEO Ruhee Diary (Lit Group)'],
          fireside: 'Sidharta Oetama',
          register:"https://bit.ly/KinovationFM",
          image: fmPoster2,
        }

      },
      // {
      //   image: fmPoster3,
      //   title: 'Demo Day 10 Selected Local Brands',
      //   date: 'Friday, 13 Oct 2023',
      //   time: '14:00 - 16:00',
      //   location: 'Senyawa+ Space, 2nd floor',
      //   location_detail: 'Jalan Raden Saleh Raya No. 46A, Cikini, Menteng, Jakarta Pusat',
      //   register:"https://wa.me/+6287775128697",
      //   // panelists: ['-'],
      //   fireside: 'Sidharta Oetama, CEO, Kino Indonesia'
      // }
    ],
    faq: [
      {
        question: 'What is Kinovation?',
        answer: 'Kinovation is a direct-to-consumer accelerator program powered by Kino Indonesia that aims to support local brands in Indonesia. This program incorporates an intensive bootcamp led by industry experts, 1-on-1 mentoring sessions, and opportunities for business collaboration.',
        showAnswer: false
      },
      {
        question: 'Who is able to join Kinovation?',
        answer: '10 Indonesian brands who pass the selection process of Kinovation.',
        showAnswer: false
      },
      {
        question: 'How many representatives can participate in Kinovation for each brand?',
        answer: 'There should be a maximum of 2 representatives from each brand to participate in Kinovation.',
        showAnswer: false
      },
      {
        question: 'Will there be a fees required by participants for joining Kinovation?',
        answer: 'There will be no participation fees nor equity fees required of participants prior to joining Kinovation.',
        showAnswer: false
      },
      {
        question: 'What will the selection process of Kinovation look like?',
        answer: 'D2C brands that are interested to join will be encouraged to fill out the registration form before 30 June 2024, 23:59 WIB. Selected brands will then go through a screening interview before the final interview.',
        showAnswer: false
      },
      {
        question: 'When and where will Kinovation be held?',
        answer: 'The third batch of Kinovation will be held in Senyawa+ Space and Kino HQ, Jakarta throughout 22 July - 22 August 2024.',
        showAnswer: false
      },
      {
        question: 'What is the time commitment of Kinovation?',
        answer: 'Bootcamp week will require a half-day commitment, while the rest of the program will only require a few hours per week, depending on the participants’ schedule. Kinovation intends to create a schedule that allows founders to maximize their learnings while still be able to make time for their brands.',
        showAnswer: false
      },
      {
        question: 'Does the participant participating in the bootcamp need to be the same person throughout the course of the program?',
        answer: 'It does not have to be, however it should be known that this program is built for founders and therefore better if there is at least one founder present throughout the course of the program.',
        showAnswer: false
      }
    ]
  },  
  mutations: {
    SET_RTL_VALUE:(state, payload) => {
      state.rtl = payload
    }
  },
  actions: {
    rtlHandler: ({ state, commit }, routePath) => {
      if (routePath) {
        if (routePath.name === 'HomeRtl') {
          state.enableRtl = true
        } else {
          state.enableRtl = false
        }
      }
      commit('SET_RTL_VALUE', !state.rtl)
      const html = document.getElementsByTagName("html");
      if (state.rtl) {
        html[0].lang = "ar";
        html[0].dir = "rtl";
      } else {
        html[0].lang = "en";
        html[0].dir = "";
      }
    }
  }
})