<template>
  <!--====== TIMELINE DETAILS START ======-->
  <section class="appie-service-details-area pb-100">
    <div class="appie-page-title-area appie-page-service-title-area topGap bannerHeight">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-sm-12">
          <div class="service-details-content timelineAlign">
            <h3 style="text-align: center; margin-bottom:20px;">Program Timeline</h3>
            <div class="d-flex justify-space-around">
              
              <v-timeline class="timelineBody">
                <v-timeline-item
                  v-for="(date, i) in dates"
                  :key="i"
                  color="#0F5EA2"
                  small
                >
                  <div class="webView" :style="`text-align: ${i % 2 === 0 ? 'right' : 'left'}; ${i % 2 === 1 ? 'margin-left: -100px' : 'margin-left: -25px'};`">
                    <span
                      :class="`timelineDate timelineWidth font-weight-bold ${date.dateColor}--text`"
                    >{{date.date}}</span>
                  </div>
                  <div class=" platView" :style="`${i % 2 === 1 ? 'margin-left: -100px' : 'margin-left: -25px'};`">
                    <h1 :class="`timelineTitle font-weight-light ${date.titleColor}--text`" :style="`text-align: ${i % 2 === 0 ? 'right' : 'left'};`">
                      {{date.title}} 
                      <p style="font-size: 0.7rem;" v-if="date.info">
                        {{date.info}}
                      </p>
                    </h1>
                  </div>
                </v-timeline-item>
              </v-timeline> 

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== TIMELINE DETAILS ENDS ======-->
</template>

<script>
import { store } from '@/store'

export default {
  data () {
    return {
      dates: store.state.timeline
    }
  },

  methods: {
    show(item) {
      item.showAnswer = !item.showAnswer
    }
  }
}
</script>

<style>
@media screen and (orientation:landscape) {
  .topGap {
    height: 100px !important;
    background-color: #1f2a5a !important;
  }
  .questionGap{
    padding-top: 30px !important;
  }
  .timelineAlign {
    position: relative;
    left: 25% !important;
    margin-top: 50px !important;
  }
  .platView {
    margin-left: 0px !important;
  }
  .webView {
    margin-left: 0px !important;
  }
  .timelineWidth {
    width: 180px !important;
  }
  .timelineBody {
    width: 80% !important;
  }
  .timelineTitle {
    font-size: 1rem;
    line-height: 1.3rem;
    letter-spacing: normal !important;
  }
  .timelineDate {
    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: normal !important;
  }
}

@media screen and (orientation:portrait) {
  .topGap {
    height: 100px !important;
    background-color: #1f2a5a !important;
  }
  .questionGap{
    padding-top: 50px !important;
  }
  .timelineAlign {
    margin-top: 50px !important;
    margin-left: -10px !important;
  }
  .platView {
    width: 150px !important;
  }
  .webView {
    width: 0px !important;
  }
  .timelineWidth {
    width: 150px !important;
  }
  .timelineBody {
    width: 35% !important;
  }
  .timelineTitle {
    font-size: 0.8rem;
    line-height: 1.2rem;
    letter-spacing: normal !important;
  }
  .timelineDate {
    font-size: 0.9rem;
    line-height: 2rem;
    letter-spacing: normal !important;
  }
}

</style>
