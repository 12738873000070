<template>
  <div>
    <!--====== BRANDS START ======-->
    <k-brand-requirements />
    <k-brand-current-batch-and-alumni />
    <!-- <k-brand-just-alumni /> -->
    <!--====== BRANDS ENDS ======-->
  </div>
</template>

<script>
import KBrandRequirements from '@/components/brands/KBrandRequirements.vue';
import KBrandCurrentBatchAndAlumni from '@/components/brands/KBrandCurrentBatchAndAlumni.vue';
// import KBrandJustAlumni from '@/components/brands/KBrandJustAlumni.vue';

export default {
  components: {
    KBrandRequirements,
    KBrandCurrentBatchAndAlumni,
    // KBrandJustAlumni
  }
}
</script>