<template>
  <!--====== HEADER MENU DETAILS START ======-->
  <header class="appie-header-area appie-header-3-area appie-sticky">
    <div class="container">
      <div class="header-nav-box header-nav-box-3">
        <div class="row align-items-center kino-header">
          <div class="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
            <div class="appie-logo-box">
              <a href="/">
                <img v-if="hideLogo" :src="logoBlack" alt="">
                <img v-if="!hideLogo" :src="logoWhite" alt="">
              </a>
            </div>
          </div>
          <div class="col-lg-10 col-md-7 col-sm-6 col-6 order-2 order-sm-3">
            <div class="appie-btn-box" style="text-align: right;">
              <div v-if="hideLogo" class="toggle-btn-dark canvas_open d-lg-none d-block">
                  <i class="fa fa-bars hamburger" @click="showSidebar"></i>
              </div>
              <div v-if="!hideLogo" class="toggle-btn-light canvas_open d-lg-none d-block">
                  <i class="fa fa-bars hamburger" @click="showSidebar"></i>
              </div>
              <div class="appie-header-main-menu">
                <k-nav-items :menuItems="menuItems" :nested="nested"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!--====== HEADER MENU DETAILS ENDS ======-->
</template>

<script>
import KNavItems from './headermenu/KNavItems.vue'
import logoWhite from '@/assets/images/Kinovation_all_white.png'
import logoBlack from '@/assets/images/kinovation.png'
import { store } from '@/store'

export default {

  components: { KNavItems },

  data () {
    return {
      hideLogo: true,
      logoWhite: logoWhite,
      logoBlack: logoBlack,
      logo: logoWhite,
      menuItems: store.state.navs
    }
  },

  props:{
    nested:{
      type:Boolean,
      default:true
    }  
  },
  
  mounted() {
    document.addEventListener('scroll', this.stickMenu)
    window.addEventListener('scroll', function() {
      var scrollPosition = window.scrollY;
      var textColor = "white";
      const result = document.getElementById("info-box")
      if (scrollPosition === 0) {
        textColor = "black"
        this.hideLogo = false
      } else {
        textColor = "white"
        this.hideLogo = true
      }
      // result.style.color = textColor; // caused errors, unknown 'style'
      console.log(this.logo, " textColor: ", textColor, " result: ", result)
    });
  },

  methods:{
    showSidebar(e){
      this.$emit("toggleSidebar",e)
    },
    stickMenu(){
    const result = document.querySelector('.appie-sticky')
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        result.classList.add('sticky')
        this.hideLogo = false
      } else {
        result.classList.remove('sticky')
        this.hideLogo = true
      }
    }
  }
}
</script>

<style>
.hamburger {
margin-right: -35px !important;
}
</style>