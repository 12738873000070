<template>
  <!--====== NEWS DETAILS START ======-->
  <section class="newsArea">
    <div style="text-align: center;">
      <img src="@/assets/images/separator2.png" width="180">
    </div>
    <div style="text-align: center;" class="mt-5">
      <div class="titleFontNews">News</div>
    </div>
    <div class="container">
      <div class="col-lg-12">
      </div>
      <div class="row mobilePad">
        <!-- Desktop -->
        <div class="appie-testimonial-slider desktopCarousel">
            <div v-for="(blog, index) in newsGroupBy3" :key="newsName + index" class="row displayV">
              <div v-for="(data, index2) in newsGroupBy3[index]" :key="newsName + index + index2" class="col-lg-4" style="display: flex;">
                <div class="appie-blog-item mt-30 wow animated fadeInUp" data-wow-duration="3000ms" data-wow-delay="200ms">
                  <a :href="data.href" target="_blank">
                    <div class="thumb">
                      <img :src="data.img" :alt="data.title" class="newsImage">
                    </div>
                    <div class="content">
                      <div class="blog-meta">
                        <ul>
                          <li>{{data.date}}</li>
                          <li>{{data.category}}</li>
                        </ul>
                      </div>
                      <h3 class="title">{{data.title}}</h3>
                      <span> Learn More <i class="fal fa-arrow-right"></i></span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
        </div>

        <!-- Mobile -->
        <div class="appie-testimonial-slider mobileCarousel">
          <div v-for="(blog, index) in newsGroupBy3" :key="newsName + index" class="row displayV">
            <div v-for="(data, index2) in newsGroupBy3[index]" :key="newsName + index + index2" class="col-lg-4" style="display: flex;">
              <div class="appie-blog-item mt-30 wow animated fadeInUp" data-wow-duration="3000ms" data-wow-delay="200ms">
                <a :href="data.href" target="_blank">
                  <div class="thumb">
                    <img :src="data.img" :alt="data.title" class="newsImage">
                  </div>
                  <div class="content">
                    <div class="blog-meta">
                      <ul>
                        <li>{{data.date}}</li>
                        <li>{{data.category}}</li>
                      </ul>
                    </div>
                    <h3 class="title">{{data.title}}</h3>
                    <span> Learn More <i class="fal fa-arrow-right"></i></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== NEWS DETAILS ENDS ======-->
</template>

<script>
import { store } from '@/store'

export default {
  
  components: {
    // VueSlickCarousel
  },

  data() {
    return {
      settings: {
        autoplay: true,
        autoplaySpeed: 10000,
        arrows:false,
        dots:true
      },

      newsName: "KinoNews",

      news: store.state.news
    }
  },

  props:{
    
  },

  methods:{
    
  },

  computed: {
    newsGroupBy3: () => {
      var group_size = 3
      var news_array = []
      var news_size = store.state.news_list.length
      var news_group_size = Math.ceil(news_size/group_size)
      // console.log(" >> news total size: ", store.state.news_list.length, " news group size: ", news_group_size)
      for (let i = 0; i < news_group_size; i++)
      {
        news_array[i] = []
        // console.log(" i: ", i)
        for (let j = 0; j < group_size; j++) {
          let news_index = (i * group_size) + j
          // console.log(" >> i: ", i, " j: ", j, " news index: ", news_index)
          if (store.state.news_list[news_index])
            news_array[i].push(store.state.news_list[news_index])
        }
      }
      // console.log(" [NEWS ARRAY] ", news_array)
      return news_array
    }
  }
}
</script>

<style>
.newsArea {
  padding-bottom: 30px !important;
}

.slick-dots li button:before {
  content: '';
}

.appie-blog-item {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* .appie-blog-item:hover .thumb img {
  transform: scale(1.5) !important;
  aspect-ratio: 2/1 !important;
} */

.appie-blog-item .content .title {
  max-width: 400px !important;

}
@media screen and (orientation:landscape) {
  .appie-testimonial-slider .slick-arrow-news.prev {
    left: 70px !important;
    color: black;
  }
  .appie-testimonial-slider .slick-arrow-news.next {
    right: 70px !important;
    color: black;
  }
  .appie-testimonial-slider .slick-arrow-news {
    top: 62% !important;
  }
  .appie-testimonial-slider .slick-arrow-news2.prev {
    left: 70px !important;
    color: black;
  }
  .appie-testimonial-slider .slick-arrow-news2.next {
    right: 70px !important;
    color: black;
  }
  .appie-testimonial-slider .slick-arrow-news2 {
    top: 73% !important;
  }
  .appie-testimonial-slider .slick-arrow-news3.prev {
    left: 70px !important;
    color: black;
  }
  .appie-testimonial-slider .slick-arrow-news3.next {
    right: 70px !important;
    color: black;
  }
  .appie-testimonial-slider .slick-arrow-news3 {
    top: 83% !important;
  }
  .mobileCarousel {
    display: none;
  }
  .titleFontNews {
    color: black !important;
    font-weight: bold;
    font-size: 32px !important;
  }
  .newsHeight {
    height: 65px !important;
    margin-top: 50px;
  }
  .mobileCenter {
    justify-content: center;
    display: flex;
  }
}

@media screen and (orientation:portrait) {
  .appie-testimonial-slider .slick-arrow-news {
    display: none !important;
  }
  .appie-testimonial-slider .slick-arrow-news2 {
    display: none !important;
  }
  .appie-testimonial-slider .slick-arrow-news3 {
    display: none !important;
  }
  .desktopCarousel {
    display: none;
  }
  .mobileCarousel {
    margin-left: 13px !important;
  }
  .mobilePad {
    padding-right: 25px;
  }
  .faqGap {
    padding-bottom: 30px !important;
  }
  .titleFontNews {
    color: black !important;
    font-size: 32px !important;
  }
  .newsHeight {
    height: 100px !important;
  }
  .mobileCenter {
    justify-content: center;
    display: flex;
  }
}

.displayV {
  display: flex !important;
}
</style>