<template>
  <section class="appie-service-details-area pb-100" style="background: #edf1f4;">
    <div class="appie-page-title-area appie-page-service-title-area topGap bannerHeight">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div style="text-align: center; margin-top: 20px;" class="">
            <img src="@/assets/images/separator.png" width="800">
          </div>
          <span class="faqFont"><b>Frequently Asked Questions</b></span>
          <div class="service-details-content">
            <div class="content" v-for="item in faq" :key="item.question" style="padding-top: 20px !important;">
              <div @click="show(item)" style="background: white; padding: 10px; display: flex; justify-content: space-between; align-items: center;">
                <h3 class="title" style="cursor: pointer; user-select: none; margin-bottom: 0px !important;">{{item.question}}</h3>
                <i :class="item.showAnswer ? 'fal fa-minus' : 'fal fa-plus'" style="color: #005e9f;"></i>
              </div>
              <div class="answer" v-show="item.showAnswer" style="background: white; padding: 10px;" >
                <p>
                  {{item.answer}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { store } from '@/store'

export default {

  data: () => ({
    faq: store.state.faq
  }),

  methods: {
    show(item) {
      item.showAnswer = !item.showAnswer
    }
  }
}
</script>

<style>
@media screen and (orientation:landscape) {
  .topGap {
    height: 100px !important;
    background-color: #1f2a5a !important;
  }
  .questionGap{
    padding-top: 30px !important;
  }
  .faqFont {
    font-size: 30px;
  }
}
@media screen and (orientation:portrait) {
  .topGap {
    height: 100px !important;
    background-color: #1f2a5a !important;
  }
  .questionGap{
    padding-top: 50px !important;
  }
  .faqFont {
    font-size: 24px;
  }
}
</style>