<template>
  <div>
    <!--====== EVENT DETAIL CONTENT START ======-->
    <k-event-detail-content />
    <!--====== EVENT DETAIL CONTENT ENDS ======-->
  </div>
</template>

<script>
import KEventDetailContent from '@/components/events/KEventDetailContent.vue';

export default {
  components: {
    KEventDetailContent
  }
}
</script>

<style>

</style>