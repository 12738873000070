<template>
  <ul>
    <li v-for="(item, index) in menuItems" :key="index">
      <!-- Modified: Using external -->
      <a v-if="item.is_external" :href="item.path" target="_blank" style="text-transform: capitalize;" :class="{ 'scrollText': hasScrolled, 'join-us': item.class }">{{ item.name }}</a>
      <router-link v-else style="text-transform: capitalize;" :class="{ 'scrollText': hasScrolled, 'join-us': item.class }" :to="item.path">{{item.name}}</router-link>
      
      <!-- Original: Using nested and children items -->    
      <!-- <router-link style="text-transform: capitalize;" :class="{ 'scrollText': hasScrolled, 'join-us': item.class }" v-if="item.path" :to="item.path">{{item.name}}</router-link>    
      <a v-else href="#" style="text-transform: capitalize;">{{item.name}} <i v-if="nested" class="fal fa-angle-down"></i></a>
      <ul v-if="item.childrens && nested" class="sub-menu">
        <li v-for="(subItem, index) in item.childrens" :key="index">
          <a v-if="subItem.is_external" :href="subItem.path" target="_blank">{{ subItem.name }} + {{ subItem.is_external }}</a>
          <router-link v-else style="text-transform: capitalize;" :to="subItem.path?subItem.path:'#'">{{subItem.name}} - {{ subItem.is_external }}</router-link>
        </li>
      </ul> -->
    </li>
  </ul>
</template>

<script>

export default {
  data () {
    return {
      scrollPosition: 0
    }
  },

  computed: {
    hasScrolled () {
      return this.scrollPosition > 0
    }
  },

  mounted() {
    window.addEventListener('scroll', () => {
    this.scrollPosition = window.pageYOffset
    })
  },

  props:{
    menuItems: {
      type:Array,
      required:true,
    },
    nested:{
      type:Boolean,
      default:true
    }
  }

}
</script>

<style>
.scrollText {
  color: white !important;
}
.join-us {
  background-color: orange;
  color: white;
  padding: 1px 20px !important;
  border-radius: 20px;
  margin-bottom: 5px;
  height: 30px;
  display: flex;
  align-items: center;
}
</style>