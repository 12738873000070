<template>
  <!--====== HERO SLIDESHOW START ======-->
  <section class="" id="testimonial" style="padding-top: 50px;">
    <div class="">
      <div class="row justify-content-center">
        <div class="col">
          <div class="" style="position:relative;">
            <!-- <span class="prev slick-arrow" style="display: block;" @click.prevent="showPrev"><i class="fal fa-arrow-left"></i></span> -->
            <VueSlickCarousel v-bind="settings" ref="carousel" :autoplay="true" :autoplaySpeed="3000">
              <template v-for="(sliderContent, index) in sliderContents">
                <div :key="index" class="appie-testimonial-item text-center">
                  <div class="author-info">
                    <b-img fluid-grow v-if="sliderContent" :src="sliderContent" class="sliderImg" alt=""/>
                  </div>
                </div>
              </template>
            </VueSlickCarousel>
            <!-- <span @click.prevent="showNext" class="next slick-arrow" style="display: block;"><i class="fal fa-arrow-right"></i></span> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== HERO SLIDESHOW START ======-->
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

// Images
import foto01 from "@/assets/images/fotokino1.jpg";
// import foto02 from "@/assets/images/fotokino2.jpg";
import foto03 from "@/assets/images/fotokino3.jpg";
import foto04 from "@/assets/images/fotokino4.jpg";
import foto05 from "@/assets/images/fotokino5.jpg";
import foto06 from "@/assets/images/fotokino6.jpg";
import foto07 from "@/assets/images/fotokino7.jpg";
import foto08 from "@/assets/images/fotokino8.jpg";
import foto09 from "@/assets/images/fotokino9.jpg";
import foto10 from "@/assets/images/fotokino10.jpeg";
import foto11 from '@/assets/images/fotokino11.jpg'
import foto12 from '@/assets/images/fotokino12.jpg';
import foto13 from '@/assets/images/fotokino13.jpg';

export default {
  components: {
    VueSlickCarousel
  },

  props: {
    // sliderContents:{
    //   type: Array,
    //   default:()=>{
    //     return []
    //   }
    // }
  },

  data() {
    return {
      settings: {
        autoplay: true,
        arrows:false,
        dots:true,
        adaptiveHeight:true,
        slidesToShow:1
      },
      sliderContents: [
        foto13,
        foto11,
        foto12,
        foto09,
        foto10,
        foto06,
        foto03,
        foto01,
        foto04,
        foto05,
        foto07,
        foto08,
      ],
    }
  },

  methods: {
    showNext() {
      this.$refs.carousel.next()
    },
    showPrev(){
      this.$refs.carousel.prev()
    },
  }

}
</script>

<style>
.sliderImg{

}
.slick-dots li button:before {
  content: '';
}
.appie-testimonial-slider .slick-arrow.prev {
  left: 20%;
}
.appie-testimonial-slider .slick-arrow {
  top: 50% !important;
}
.v-application ol, .v-application ul {
  padding-left: 0 !important;
}

</style>