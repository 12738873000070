<template>
  <!--====== EVENT DETAIL CONTENT START ======-->
  <section class="appie-service-details-area pb-100">
    <div class="appie-page-title-area appie-page-service-title-area timelineGap bannerHeight" style="background-color: #1f2a5a;">
    </div>
    <div class="container">
      <div class="row" style="margin-top: 20px;">
        <div class="col-md-6">
          <img :src="currentEvent.details.image" width="550">
        </div>
        <div class="col-md-6">
          <span style="font-size: 24px;"> Founders Meetup: </span>
          <div class="eventDetail" style="font-size: 24px;"> <b>{{ currentEvent.title }}</b> </div>
          <div class="eventDetail"> 
            <img :src="calendarIcon" width="40"/>
            {{ currentEvent.date }} 
          </div>
          <div class="eventDetail"> 
            <img :src="timeIcon" width="40"/>
            {{ currentEvent.details.time }} 
          </div>
          <div class="eventDetail" style="align-items: center; display: flex;"> 
            <img :src="locationIcon" width="40"/>
            <span style="line-height: 1;">
              {{ currentEvent.details.location }}
              <br>
              <span style="font-size: 12px;"> {{ currentEvent.details.location_detail }} </span>
              <br>
            </span>
          </div>
          <div class="eventDetail" v-if="currentEvent.details.fireside">
            <span>Fireside Chat :</span>
            <li>
              {{ currentEvent.details.fireside }}
            </li>
          </div>
          <div class="eventDetail" v-if="currentEvent.details.panelists">
            <span>Panelists : </span>
            <li v-for="item in currentEvent.details.panelists" :key="item">
                {{ item }}
            </li>
          </div>
          <a :href="currentEvent.details.register" target="_blank"> 
            <div class="join-us" style="align-items: center; justify-content: center; color: black !important;">
              Register Now
            </div>
          </a>    
        </div>
      </div>
    </div>
  </section>
  <!--====== EVENT DETAIL CONTENT ENDS ======-->
</template>

<script>
import { store } from '@/store'
import timeIcon from "@/assets/images/events/time.png";
import calendarIcon from "@/assets/images/events/calendar.png";
import locationIcon from "@/assets/images/events/location.png";

export default {
  data () {
    return {
      timeIcon: timeIcon,
      calendarIcon: calendarIcon,
      locationIcon: locationIcon,
      eventId: this.$route.params.id,
      currentEvent: null
    }
  },

  methods: {
    fetchEvenData(eventId) {
      // find the event based on eventId
      for (let index in store.state.events) {
        let event = store.state.events[index]
        if (event.id == eventId) {
          this.currentEvent = event
        }
      }
    }
  },

  created() {
    this.fetchEvenData(this.eventId)
  }
}

</script>

<style>
.eventDetail {
  margin-bottom: 10px;
}
</style>