<template>
  <section class="appie-service-details-area pb-100">
    <div class="appie-page-title-area appie-page-service-title-area topGap bannerHeight">
    </div>
    <div class="container">
      <h3 style="text-align: center; color: #1f2a5a; margin-bottom:20px; margin-top:20px;">Our Events</h3>
      <div class="row justify-content-center" style="display: flex; align-items: flex-start; text-align: center">
        <div class="col-md-4" v-for="item in events" :key="item.id">
          <router-link :to="getDetailPath(item)">
          <!-- <a href="https://bit.ly/KinovationFM"> -->
            <div style="height: 250px; border-width: 2px; border-style: solid; border-color: #909090; align-items: center; display: flex; justify-content: center;">
              <img :src="item.image" style="padding: 15px;"/>
            </div>
            <p style="margin-top: 10px;"> {{ item.date }} </p>
            <span style="font-size: 22px; color: black;" v-if="!item.hide"> Founders Meetup: </span>
            <!-- <span style="font-size: 22px; color: black;" v-if="item.hide"> &nbsp; </span> -->
            <p style="font-size: 22px; color: black;"> {{ item.title }} </p>
          <!-- </a> -->
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { store } from '@/store'

export default {
  data () {
    return {
      events: store.state.events
    }
  },

  methods: {
    show(item) {
      item.showAnswer = !item.showAnswer
    },

    getDetailPath(item) {
      if (item.canShowDetails) {
        return '/event/' + item.id
      }
      return ''
    }
  }
}
</script>

<style>
@media screen and (orientation:landscape) {
  .topGap {
    height: 100px !important;
    background-color: #1f2a5a !important;
  }
}
@media screen and (orientation:portrait) {
  .topGap {
    height: 100px !important;
    background-color: #1f2a5a !important;
  }
}
</style>