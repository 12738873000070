<template>
  <!--====== BRAND REQUIREMENTS START ======-->
  <section class="appie-services-2-area pb-100 contentGap" style="background-color: #005e9f" id="service">
    <div class="container" style="color: white;">
      <div style="text-align: center;" class="mt-50 mb-50">
        <img src="@/assets/images/separator.png" width="1000">
      </div>
      <div style="justify-content: center; text-align: center; display:flex;">
        <div class="col-lg-10 col-md-8">
          <div class="appie-section-title">
            <span style="justify-content: center; margin-bottom: -20px;" class="appie-title titleFontBlack">{{title}}</span>
            <p>{{description}}</p>
          </div>
        </div>
      </div>
      <div class="row" style="text-align: center;">
          <div class="col-lg-4 col-md-6 mt-30">
            <div class="appie-single-service-2">
              <div class="icon" style="margin-bottom: 30px;">
                <img src="@/assets/images/Brands_1.png" width="70">
              </div>
              <p class="descFont"><b>Self-owned brand</b> with<br> solid founding team &<br> strong backgrounds</p>
              <!-- <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a> -->
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mt-30">
            <div class="appie-single-service-2">
              <div class="icon" style="margin-bottom: 30px;">
                <img src="@/assets/images/Brands_2.png" width="70">
              </div>
              <p class="descFont">Good traction & potential scalability with <b>roadmap for product-market fit</b></p>
              <!-- <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a> -->
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mt-30">
            <div class="appie-single-service-2 wow animated fadeInUp">
              <div class="icon" style="margin-bottom: 30px;">
                <img src="@/assets/images/Brands_3a.png" width="90">
              </div>
              <p class="descFont">Have a <b>unique and innovative</b> product</p>
              <!-- <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a> -->
            </div>
          </div>
          <div class="col-lg-4 re-center col-md-6 mt-30">
            <div class="appie-single-service-2 wow animated fadeInUp">
              <div class="icon" style="margin-bottom: 30px;">
                <img src="@/assets/images/Brands_4.png" width="70">
              </div>
              <p class="descFont"><b>Not participating</b> in<br> another accelerator program</p>
              <!-- <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a> -->
            </div>
          </div>
          <div class="col-lg-4 re-center col-md-6 mt-30">
            <div class="appie-single-service-2 wow animated fadeInUp" style="padding-left: 75px; padding-right: 75px;">
              <div class="icon" style="margin-bottom: 30px;">
                <img src="@/assets/images/Brands_5.png" width="50">
              </div>
              <p class="descFont"><b>ESG & wellness value</b><br> would be a plus</p>
              <!-- <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a> -->
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-6">
            <div class="appie-single-service-2 item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
              <div class="icon">
                <i class="fas fa-lock"></i>
              </div>
              <h4 class="title">Fast and intuitive</h4>
              <p>Oxford posh bevvy give us a bell gutted mate spend a penny quaint cockup plastered.</p>
              <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a>
            </div>
          </div> -->
      </div>
    </div>
  </section>
  <!--====== BRAND REQUIREMENTS ENDS ======-->
</template>

<script>
export default {

  data() {
    return {
      title: "Selecting 10 high potential Indonesian brands",
      description: ""
    }
  },

}
</script>

<style>
.descFont {
  color: white;
}
@media screen and (orientation:landscape) {
  .re-center {
    justify-content: center;
    display: flex;
    transform: translate(200px, 10px);
  }

  .contentGap {
    padding-top: 5vh;
  }
  
  .titleFontBlack {
    font-size: 32px !important;
    color: white;
  }
}

@media screen and (orientation:portrait) {
  .contentGap {
    padding-top: 80px;
  }
  .titleFontBlack {
    font-size: 26px !important;
    color: white;
  }
}
</style>