<template>
  <div>
    <!--====== EVENTS START ======-->
    <k-event-items />
    <!--====== EVENTS ENDS ======-->
  </div>
</template>

<script>
import KEventItems from '@/components/events/KEventItems.vue';

export default {
  components: {
    KEventItems,
  }
}
</script>

<style>

</style>