<template>
  <div>
    <!--====== REGISTER START ======-->
    <k-register-detail />
    <!--====== REGISTER ENDS ======-->
  </div>
</template>

<script>
import KRegisterDetail from '../components/register/KRegisterDetail.vue';

export default {
  components: {
    KRegisterDetail
  }
}
</script>

<style>

</style>