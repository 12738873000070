<template>
  <!--====== BRANDS DETAILS START ======-->
  <section class="pb-50 pt-50" id="brands">
    <div style="text-align: center;" class="">
      <img src="@/assets/images/separator2.png" width="180">
    </div>
    <div style="text-align: center;" class="brandsText mt-5" ref="brandsRef">
      <div class="brandsTitle">{{currentBatchName}} Selected Local Brands</div>
    </div>
    <div class="container mt-5">
      <div class="row">
        <!-- <div class="col-12" style="text-align:center;">
          (Coming Soon!)
        </div> -->
        <div class="col-12" style="text-align:center;">
          <div class="service-details-content">
          <img src="@/assets/images/brands/batch03/alamme.png" width="165" class="mobileImg3Top topBrands">
          <img src="@/assets/images/brands/batch03/arummi.png" width="165" class="mobileImg3Top topBrands">
          <img src="@/assets/images/brands/batch03/crystal.png" width="165" class="mobileImg3Top topBrands">
          <img src="@/assets/images/brands/batch03/ezenails.png" width="135" class="mobileImg3Top topBrands">
          <img src="@/assets/images/brands/batch03/lashboss.png" width="105" class="mobileImg3Top topBrands">
          </div>
        </div>
        <div class="col-12" style="text-align:center;">
          <div class="service-details-content">
          <img src="@/assets/images/brands/batch03/nona.png" width="165" class="mobileImg3Top topBrands">
          <img src="@/assets/images/brands/batch03/togean.png" width="165" class="mobileImg3Top topBrands">
          <img src="@/assets/images/brands/batch03/vetpicurean.png" width="165" class="mobileImg3Top topBrands">
          <img src="@/assets/images/brands/batch03/worldofmakescents.png" width="135" class="mobileImg3Top topBrands">
          <img src="@/assets/images/brands/batch03/yorico.png" width="135" class="mobileImg3Top topBrands">
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>

    <div style="text-align: center;" class="brandsText mt-9" ref="brandsRef">
      <v-btn
        :disabled="containerOpen.hide === true"
        @click="toggleAlumni"
        size="small"
        class="alumni-btn rounded-pill flat"
        style="background:#f1a93c; color:white;"
      >
        Our Alumni
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </div>
  
    <div ref="alumniRef"></div>
  
    <transition name="fade" >
      <div class="container mt-10" v-if="containerOpen.hide">
        <div class="row">
          <div class="col-12 d-flex justify-center">
            <div style="text-align: center;" class="brandsText mt-5">
              <span class="brandsTitle">Our Alumni</span>
            </div>
          </div>
          <div class="col-12 d-flex justify-center" >
              <v-btn
                v-if="containerOpen.hide"
                @click="toggleAlumni"
                size="small"
                class="alumni-btn rounded-pill flat"
                style="background:#f1a93c; color:white;"
              >
                Hide Our Alumni
              <v-icon right>mdi-chevron-up</v-icon>
              </v-btn>
          </div>
        </div>
        <k-brands-only/>
      </div>
    </transition>
  </section>
  <!--====== BRANDS DETAILS ENDS ======-->
</template>
<script>
import { store } from '@/store'
import KBrandsOnly from './KBrandsOnly.vue'

export default {
  components: {
    KBrandsOnly
  },
  data () {
    return {
      currentBatchName: store.state.currentBatchName,
      containerOpen:{hide:false}
    }
  },
  methods: {
    show(item) {
      item.showAnswer = !item.showAnswer
    },
    toggleAlumni(){
      this.containerOpen.hide = !this.containerOpen.hide

      // Added scroll animation to make the transition smoother 
      if(this.containerOpen.hide){
        // Target ref
        const targetElem = this.$refs.alumniRef;

        // Scroll to target
        targetElem.scrollIntoView({ behavior: 'smooth' });
      } else {
        // Target ref
        const targetElem = this.$refs.brandsRef;

        // Scroll to target
        targetElem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  
  }
}
</script>
<style>
.alumni-btn{
  color:white;
  background:#f1a93c;
  box-shadow: none;
}

.alumni-btn .v-btn__content {
  text-transform: none;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media screen and (orientation:landscape) {
  .questionGap{
    padding-top: 30px !important;
  }
  .topAlign {
    position: relative;
    left: 16%;
  }
  .botAlign {
    position: relative;
    left: 15%;
  }
  .exBrands {
    margin-left: -120px !important;
    margin-right: -160px !important;
  }
  .exBrandsBot {
    margin-left: -25px !important;
    margin-right: -10px !important;
  }
  .brandsText {
    color: black;
    width: 100%;
    font-size: 32px !important;
    /* margin-bottom: 30px !important; */
  }
  .brandsTitle {
    /* padding: 10px; */
    font-weight:bold;
  }
}

@media screen and (orientation:portrait) {
  .questionGap{
    padding-top: 50px !important;
  }
  .exBrands {
    width: 90% !important;
    margin-left: -65px !important;
    margin-right: -160px !important;
    margin-top: -30px !important;
  }
  .exBrandsBot {
    width: 55% !important;
    margin-left: -15px !important;
    margin-top: 40px !important;
  }
  .topAlign {
    position: relative;
    left: 10px !important;
  }
  .botAlign {
    position: relative;
    left: 10px !important;
  }
  .brandsText {
    color: black;
    width: 100%;
    font-size: 32px !important;
    /* margin-bottom: 30px !important; */
  }
  .brandsTitle {
    padding: 10px;
  }
}

</style>
